<template>
<div>
  <div class="suspends" v-if="loadShow"
      ref="qiyu"
      :style="{'left': x + 'px', 'top': y + 'px' }"
      @click="changePrice"
      @mousedown.stop="dragStartHandler"
      @touchstart.stop="dragStartHandler"
      @mousemove.stop="draggingHandler"
      @touchmove.stop="draggingHandler"
      @mouseup.stop="dragEndHandler"
      @touchend.stop="dragEndHandler" 
      >
          <p>{{initPriceName == 'LON_Au'?'伦敦金':'黄金'}}</p><br/>
          <p class="fontWeight">{{ price.toFixed(2)}}</p> 
       </div>
  </div>
</template>

<script>
import Stomp from 'stompjs'
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['suspend']),
    },
    props:{
        loadShow:{
          type:Boolean,
          default:true
        },
        initPriceName:{
          type:String,
          default:"LON_Au"
        }
    },
    data() {
        return {
    //获取手指初始坐标
	  startX:0,
	  startY:0,
      // x轴坐标
      x: window.screen.width - 70,
      // y轴坐标
      y:300,
      // 元素宽度
      w:+'',
            client: {},
            // 所有商品数据
            allpro:[],
            price:0,
            // 是否开盘
            trade_: false,
            // 提交时间
            postTime:'',
        }
    },
    watch:{
          allpro:function(){
              this.updatearr(this.initPriceName)
            }
    },
    methods:{
      // touchstart 当手指触摸屏幕时候触发
    dragStartHandler (e) {
      //  e.preventDefault()
      let touch = e
      if (e.touches) {
        touch = e.touches[0]
      }
      this.startX = touch.clientX - e.target.offsetLeft
      this.startY = touch.clientY - e.target.offsetTop
    },
    //  touchmove 当手指在屏幕上滑动的时候连续地触发
    draggingHandler (e) {
      e.preventDefault()
      this.w = e.target.clientWidth
      let touch = e
      if (e.touches) {
        touch = e.touches[0]
      }
      // 设置拖拽元素的位置
      this.x = touch.clientX - e.target.clientWidth /2
      this.y = touch.clientY -  e.target.clientHeight /2
      // 限制元素不能超过屏幕

      // 元素超出左边屏幕10的距离
      if (this.x < -10 ) {
        // 元素超出的距离就等于当前元素宽度的一半
        this.x = 0
      } else if( this.x >-9 && this.x < 0){   //如果元素超出屏幕且不超过9的距离
        this.x = 0  //元素不能超出屏幕
      }
      //如果元素超出右边屏幕的距离
       else if (this.x > window.screen.width - e.target.clientWidth +e.target.clientWidth/2 - 3) {
        //  元素超出的距离就等于当前元素宽度的一半
        this.x = window.screen.width - e.target.clientWidth
      }
      // 元素超出屏幕高度
      if (this.y < 0) {
        this.y = 0
      } else if (this.y >  window.screen.height -  e.target.clientHeight - 200) {  //限制元素不能超出的屏幕底部距离
        this.y = window.screen.height -  e.target.clientHeight - 200
      }
    },
    // touchcancel当系统停止跟踪触摸的时候触发
    dragEndHandler (e) {
      // e.preventDefault()
      if (this.x < -10) {
           this.x = -e.target.clientWidth/2
      }
      else if(this.x  > window.screen.width - e.target.clientWidth + 10){
          this.x = window.screen.width - e.target.clientWidth
      }
    },
    changePrice(){
        this.$router.push({
          name:"suspends",
          query:{backPath: this.$route.name }
        })
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
       this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
          if (!this.client.connected) {
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    deepMerge(...objs) {
      const than = this
      const result = Object.create(null)
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val)
              } else {
                result[key] = than.deepMerge(val)
              }
            } else {
                //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val]
              } else {
                result[key] = val
              }
            }
          })
        }
      })
      return result
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString
      return toString.call(val) === '[object Object]'
    },
    responseCallback (frame) {
      this.allpro = []
      const resbody = eval('['+this.unzip(frame.body)+']')[0]
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time
      if(this.firstloadData){
        this.initDatalist = resbody
        this.firstloadData = false
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }else{
        this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }
    },
    // 价格变动更新视图
    pricenew(obj){
      this.allpro.find((x)=>{
         if(x[obj]){
          this.price = x[obj]['huigou']
        }
      })
    },
    // 更新数组
    updatearr(obj){
            this.pricenew(obj)
    },
    },
    mounted(){
      this.connect()
    },
    created(){
    },
    destroyed() {
    clearTimeout(this.setInterval)
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    })
    },
}
</script>

<style lang="scss">
.suspends{
    position: fixed;
    top: 0;
    right: 0;
    width: 4.2rem;
    height:2.6rem;
    border-radius: 50px;
    z-index: 1 !important;
    background: #39394d;
    text-align: center;
    color: #f6d0ab;
    font-size: 24px;
        .fontWeight{
            font-weight: 700;

        }
}
p {
  display: inline-block;
  vertical-align: middle;
}
</style>